<template>
    <head></head>
    <div class="h-screen bg-gray-50">
        <div class="w-full h-full">
            <div class="flex justify-center items-center pt-3 h-full w-full">
                <div class="w-1/3">
                    <div class="text-center mb-3 flex justify-center">
                        <img src="assets/images/logo.png" height="50px" />
                    </div>
                    <div class="bg-white rounded shadow h-auto" id="login-card">
                        <div class="p-3">
                            <form class="container">
                                <div class="mb-3">
                                    <label for="username" class="mb-1 block">Username</label>
                                    <a-input id="username" type="text" name="username" placeholder="Username..." required auto-focus v-model:value="user.username"/>
                                </div>

                                <div class="mb-3">
                                    <label for="password" class="mb-1 block">Password</label>
                                    <a-input id="password" type="password" name="password" required autocomplete="current-password" placeholder="Password..." v-model:value="user.password"/>
                                </div>

                                <a-checkbox v-model:checked="user.remember">Remember Me</a-checkbox>

                                <a-button type="primary" id="login-btn" class="btn-dark mt-3 mb-3 w-full" :loading="loading" @click="login($event)">Login</a-button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import User from '../models/user';
export default {
    data() {
        return {
            user: new User('', '', false),
            loading: false,
            message: '',
        };
    },
    methods: {
        login(e) {
            e.preventDefault();
            this.loading = true;
            if (this.user.username && this.user.password) {
                this.$store.dispatch('auth/login', this.user).then(() => {
                    const redir = localStorage.getItem('redirect');
                    if(redir) {
                        localStorage.removeItem('redirect');
                        this.$router.push(redir);
                    } else {
                        this.$router.push('/queues');
                    }
                },
                error => {
                    this.loading = false;
                    this.message = (error.response && error.response.data) || error.message || error.toString();
                });
            } else {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
